import React, { useState, useContext, useEffect } from 'react'
import { PaymentContext } from '../Context/PaymentContext'
import Receipt from '../Components/Receipt/Receipt'
import {useHistory , useLocation} from 'react-router-dom'
import Header from '../Components/Header/Header'
import { Notifier } from '../Components/Notifier/Notifier'



export const CountDownSms = () => {
    const history = useHistory()
    const location = useLocation()
    const { darkMode } = useContext(PaymentContext)
    const getSetTime = localStorage.getItem('expTimeInSecs')
    const [showReceipt, setShowReceipt] = useState(false)
    const [isSuccess,setIsSuccess] = useState(true)
    const expiredTime = localStorage.getItem('expTime')
    const [darkModeStyle, setDarkModeStyle] = useState({
        globalContainer: 'global_container',
        color: '#585858',
        circleAnimation: 'circleAnimation',
        countDownAdj: 'countdown_adjustment'
    })
    const [notifications, setNotifications] = useState(null)
        var orange;
        var orangeTxt;
        var verbageChange = 'Active Session'

    const [containerStyling, setContainerStyling] = useState({
        divStyle: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        },
        buttonStyle: {
            marginTop: '30px',
            border: '1px solid #848484',
            fontSize: '13px',
            // border: '1px solid #FF4F4F',
            padding: '10px',
            borderRadius: '10px',
            margin: '0px 5px 0px 5px',
            boxShadow: '10px 10px 30px rgba(174, 174, 192, 0.4), -10px -10px 30px #FFFFFF'
        },
        buttonDiv: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-around'
        }
    })
    const toggle = () => {
        setShowReceipt(!showReceipt)
    }


const [activeSession, setActiveSession] = useState({
    style: {

    }
})

    var getExpTimeInSecs = localStorage.getItem('expTimeInSecs')
    var date = new Date()
    var d = 0
    var m = date.getMinutes() * 60
    var h = date.getHours() * 3600
    var s = date.getSeconds()
    var sum = 0


    var hour_ = Math.floor(getSetTime / 100) * 3600
    var minutes_ = Math.round(getSetTime % 100) * 60
    var count = getSetTime - sum
    // console.log(expiredTime)


    // Add this to local storage as the initial time
    const [countDown, setCountDown] = useState({
        days: Math.floor(getSetTime / 100),
        hours: Math.floor(getSetTime / 100),
        mins: Math.round(getSetTime % 100),
        secs: 60
    })



    var counter = null;



    const tryagain = () => {
        history.push(`/?id=${localStorage.getItem("lot")}`)
    }

    useEffect(() => {
        if (darkMode >= 1800 || darkMode <= 600) {
            setDarkModeStyle({
                globalContainer: 'global_container_dark',
                color: 'white',
                submitBtn: 'submitBtn_dark',
                circleAnimation: 'circleAnimation_dark',
                countDownAdj: 'countdown_adjustment_dark'
            })
            setContainerStyling({
                divStyle: {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                },
                buttonStyle: {
                    marginTop: '30px',
                    border: '1px solid #EA4335',
                    fontSize: '13px',
                    // border: '1px solid #FF4F4F',
                    padding: '10px',
                    borderRadius: '10px',
                    margin: '0px 5px 0px 5px',
                    color: '#EA4335',
                    background: '#1E2834',
                    boxSizing: 'border-box',
                    boxShadow: '10px 10px 30px rgba(0, 0, 0, 0.6), -10px -10px 30px rgba(255, 255, 255, 0.12)'

                },
                buttonDiv: {
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-around'
                }
            })
        }
            const id = location.search.slice(1).split("&")[0].split("=")[1];
        fetch(`${process.env.REACT_APP_URL_ONESHOT}/v1/Park/GetDetail?reqId=${id}`, {
            method: 'GET',
            headers: {"Content-Type": "application/json"},

        }).then((res) => res.json())
            .then((data) => {

                if (data.data.status == "Fail"){
                    setIsSuccess(false)

                }else if (data.data.status == "Done") {

                    var x1 = JSON.parse(data.data.metaData)
                    localStorage.setItem("expTime",x1.end)
                    // setTimeout(()=>{
                    if( data.data.diffMinutes < 0){
                        localStorage.setItem("count",0)
                        localStorage.setItem("expTimeInSecs",0)
                    }else
                    {
                        localStorage.setItem("count",x1.count)
                        localStorage.setItem("expTimeInSecs",x1.sec)
                    }

                    // },1000)
                    localStorage.setItem("startTime",data.data.startDate)
                    localStorage.setItem("endTime",data.data.endDate)
                    localStorage.setItem("phone",data.data.phoneNumber)
                    localStorage.setItem("license",data.data.plateNo)
                    localStorage.setItem("fullname",data.data.fullName)
                    localStorage.setItem("parkTitle",data.data.parkTitle)
                    localStorage.setItem("lotId",data.data.lotId)
                    // localStorage.setItem("expTime",data.data.endDate)
                    localStorage.setItem("total",data.data.price)
                    localStorage.setItem("realTime",data.data.diffMinutes)
                    localStorage.setItem("lot",x1.lot)
                    localStorage.setItem("endDateTimeStamp",data.data.endDateTimeStamp)


                    setTimeout(()=>{

                        history.push(`/remaining-time/?id=${id}`)

                    },3000)


                }
            })

    }, [])


    return (
        <>
            <Header home={true}/>
            <div style={{overflowY: 'hidden'}} className={`${darkModeStyle.globalContainer} choose_lot ${orange}`}>
                <div style={containerStyling.divStyle}>
                    <div className='pulse_holder'>
                    <div className={darkModeStyle.circleAnimation}></div>
                    <div className='countdown_digits_holder'>
                        <div className={`${darkModeStyle.countDownAdj} ${orange}`}>
                            {isSuccess && <h3 className={orangeTxt} style={{ marginBottom: '0px' }}>Loading . . .</h3>}
                            {!isSuccess && <h3 className={orangeTxt} style={{ marginBottom: '0px',color:"red" }}>Payment failed</h3>}
                        </div>
                    </div>

                    </div>

                    {!isSuccess &&   <input type='submit' className="btn-confirm content-align plateCapture" onClick={tryagain} value="try Again" style={{marginTop:"40%"}}></input> }

                </div>
            </div>

        </>
    )
}










// console.log(time.getSeconds())
// localStorage.setItem('secs', updated_secs--)

// if(updated_secs <= 0 ){
//     localStorage.setItem('secs', 60)

//  clearInterval(run)
//         // localStorage.setItem('mins', updated_mins)
//         // localStorage.setItem('secs', 60)
//     }
// if(updated_mins == 0){
//     updated_hours--
//     localStorage.setItem('hours', updated_hours)
//     localStorage.setItem('mins', 60)
//     // updated_mins = 60
// }
// if(updated_hours == 0){
//     localStorage.setItem('hours', '00')
//     // updated_hours = '00'
// }
