import React, { useContext, useEffect, useState } from 'react'
import { PaymentContext } from '../Context/PaymentContext'
import { withRouter, useHistory } from 'react-router-dom'
import Header from '../Components/Header/Header'
import Loading from '../Components/Loading/Loading'


const GuestDataCapture = (props) => {
    const history = useHistory()
    const { setUserInfo, darkMode } = useContext(PaymentContext)
    const [darkModeStyle, setDarkModeStyle] = useState({
        globalContainer: 'global_container',
        buttonStyle: 'submitBtn',
        color: '#585858'

    })
    // const [dataUser,setDataUser] = useState(JSON.parse(localStorage.getItem("Live")))
    const [name,setName] =  useState(localStorage.getItem("fullname"))
    const [phone,setPhone] =  useState(localStorage.getItem("phone"))
    const [isLoading,setIsLoading] =  useState(false)

    // if(localStorage.getItem("fullname")){
    //     setName(localStorage.getItem("fullname"))
    //
    // }else if(localStorage.getItem("phone")){
    //     setPhone(localStorage.getItem("phone"))
    //
    // }else if(localStorage.getItem("license")){
    //     setLicense({lp:localStorage.getItem("license")})
    //
    // }

    const [license, setLicense] = useState({
        lp: localStorage.getItem("license")
    })


    const changeTxt = (e) => {
        setLicense({...license, 'lp': e.target.value.split(' ').join('')})
        localStorage.setItem('license', e.target.value.split(' ').join(''))

    }
    const changeTxtName = (e) => {
        setName(e.target.value)
        localStorage.setItem('fullname', e.target.value)

    }
    const changeTxtPhone = (e) => {
        setPhone(e.target.value)
        localStorage.setItem('phone', e.target.value)

    }



console.log(license)

    useEffect(() => {
        localStorage.setItem('backbutton', false)
        if (darkMode >= 1800 || darkMode <= 600) {
            setDarkModeStyle({
                globalContainer: 'global_container_dark',
                buttonStyle: 'submitBtn_dark',
                color: 'white'

            })
        }
    }, [])

    const submitedDataCapture = (e) => {
        e.preventDefault()
        localStorage.setItem('fullname', e.target.name.value)
        localStorage.setItem('phone', e.target.phone.value)
        localStorage.setItem('license', e.target.license.value)
        setUserInfo({
            name: e.target.name.value,
            phone: e.target.phone.value,
            license: e.target.license.value
        })
        // history.push(`/select-payment/`)
    }
    const lotNumber = localStorage.getItem('lot')

    const terms_onclick = () => {
        history.push('/terms/')
    }

    const api_data = {
        'fullname': localStorage.getItem('fullname'),
        'license_plate': localStorage.getItem('license'),
        'set_time': localStorage.getItem('startTime'),
        'exp_time': localStorage.getItem('expTime'),
        'location_id': localStorage.getItem('lot'),
        'phone': localStorage.getItem('phone'),
        'token': localStorage.getItem('token'),
        'amount': localStorage.getItem('totalServer'),
        'expireTime': localStorage.getItem('expTimeInSecs'),
        'realTimeServer': localStorage.getItem('realTime'),
        'startTime': localStorage.getItem('startTime'),
        'count': localStorage.getItem('count'),
        'paid': localStorage.getItem('total'),
        'previousId': localStorage.getItem('guid')
    }


    const sendDataSuccess = (e)=>{
        e.preventDefault()
        localStorage.setItem('fullname', e.target.name.value)
        localStorage.setItem('phone', e.target.phone.value)
        localStorage.setItem('license', e.target.license.value)
        setUserInfo({
            name: e.target.name.value,
            phone: e.target.phone.value,
            license: e.target.license.value
        })

        setIsLoading(true)

        try {

            if (localStorage.getItem("typePay") == "extend"){

                var startTime = localStorage.getItem("endDateTimeStamp")

            }else{
                var startTime = Date.now()+10000
            }

        }catch (e){
            var startTime = Date.now()+10000

        }

        fetch(`${process.env.REACT_APP_URL_ONESHOT}/v2/Park/Reserve`, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                "fullName": api_data.fullname == null  ? name : api_data.fullname,
                "phoneNumber": api_data.phone == null ? phone : api_data.phone,
                "plateNo": api_data.license_plate == null ? license.lp : api_data.license_plate,
                "startDateTimeStamp": startTime,
                "duration": api_data.realTimeServer,
                "lotId":api_data.location_id,
                "PreviousId":api_data.previousId,
                "metaData":JSON.stringify({"sec":api_data.expireTime,"start":api_data.startTime,"end":api_data.exp_time,"count":api_data.count,"lot":api_data.location_id}),
            }),
        }).then((res) => res.json())
            .catch((error) => {
                alert('Error1:')})
            .then((data) => {

                if (data.data){

                    window.location.replace(data.data.url);

                    // callBack(data.data.reqId)

                }else {
                    alert(data.error.message)
                    setIsLoading(false)

                }
                // alert(data.data.token)
                // if(data.status === 400){
                //     setNotifications(`Error >>> ${data.message}`)
                // }
                // else{
                //     setNotifications(null)
                //     setClientSecret(data.clientSecret)
                // }
            })


    }


    const callBack = (id) =>{
        fetch(`${process.env.REACT_APP_URL_ONESHOT}/v1/Park/GetDetail?reqId=${id}`, {
            method: 'GET',
            headers: {"Content-Type": "application/json"},

        }).then((res) => res.json())
            .then((data) => {
                var x1 = JSON.parse(data.data.metaData)
                localStorage.setItem("expTime",x1.end)
                // setTimeout(()=>{
                if( data.data.diffMinutes < 0){
                    localStorage.setItem("count",0)
                    localStorage.setItem("expTimeInSecs",0)
                }else
                {
                    localStorage.setItem("count",x1.count)
                    localStorage.setItem("expTimeInSecs",x1.sec)
                }

                // },1000)
                localStorage.setItem("startTime",data.data.startDate)
                localStorage.setItem("endTime",data.data.endDate)
                localStorage.setItem("phone",data.data.phoneNumber)
                localStorage.setItem("license",data.data.plateNo)
                localStorage.setItem("fullname",data.data.fullName)
                // localStorage.setItem("expTime",data.data.endDate)
                localStorage.setItem("total",data.data.price)
                localStorage.setItem("realTime",data.data.diffMinutes)
                localStorage.setItem("lot",x1.lot)
                localStorage.setItem("endDateTimeStamp",data.data.endDateTimeStamp)

            })

        setTimeout(()=>{
            history.push(`/remaining-time/?id=${id}`)

        },1500)
    }





    return (
        <>
		<Header home={false}/>
        <div className={`${darkModeStyle.globalContainer} choose_lot`}>
            <div className='content_holder'>
                {!isLoading && <> <h1 className='WelcomeHeadder' style={{ textAlign: 'center', color: darkModeStyle.color }}>Welcome to Lot Location<br /> <strong>{lotNumber}</strong></h1>
                 <form className='form' onSubmit={sendDataSuccess}>
                    <label style={{ color: darkModeStyle.color}} className='labels'>Full Name</label>
                    <input name='name' required  value={name} onChange={(e) => {changeTxtName(e)}}></input>
                    <label style={{ color: darkModeStyle.color }} className='labels'>Phone Number</label>
                    <input name='phone' required value={phone} onChange={(e) => {changeTxtPhone(e)}}></input>
                    <label style={{ color: darkModeStyle.color }} className='labels'>License Plate</label>
                    <input required name='license' maxlength="7" onChange={(e)=>{changeTxt(e)}} value={license.lp} style={{textTransform:"uppercase"}}></input>

                    <input className={darkModeStyle.buttonStyle}  type='submit'></input>
                    <div className='tc_holder'>
                    <button onClick={terms_onclick} className='terms_condition'>Terms & Conditions</button>
                    </div>

                </form> </> }
                {isLoading && <Loading/> }


            </div>

        </div>
        </>
    )
}
export default withRouter(GuestDataCapture);
