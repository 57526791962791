import React, { useState, useContext, useEffect } from 'react'
import { PaymentContext } from '../Context/PaymentContext'
// import { CardNumberElement, CardCvcElement, CardExpiryElement, useElements, useStripe } from '@stripe/react-stripe-js'
//import { loadStripe } from '@stripe/stripe-js'
import Lock from '../Assets/lock.png'
import CreditCardIcon from '../Assets/ccIcon.svg'
import '../Pages/pages.css'
import Loading from '../Components/Loading/Loading'
import {withRouter, useHistory} from 'react-router-dom'
import gsap from 'gsap'
import Header from '../Components/Header/Header'
import { Notifier } from '../Components/Notifier/Notifier'

import {Helmet} from "react-helmet";
import InputMask from 'react-input-mask';
import * as Sentry from "@sentry/react";


const StripePayment = () => {
    // using state to keep track of stripe payment, show errors, and manange user interface
    var button_message = 'Pay Nows'
    // const stripe = useStripe()
    // const elements = useElements()

    const history = useHistory()
    const [success, setSuccess] = useState(false)
    const [status, setStatus] = useState({
        status: "Enter your card's information below"
    })
    const [processing, setProcessing] = useState('')
    const [tokenPay, setTokenPay] = useState('')
    const [disabled, setDisabled] = useState(true)
    const [clientSecret, setClientSecret] = useState('')
    const [notifications, setNotifications] = useState(null)
    const { userInfo, totalbilling, darkMode } = useContext(PaymentContext)
    const [isPaymentLoading, setPaymentLoading] = useState(false)
    const [darkModeStyle, setDarkModeStyle] = useState({
        globalContainer: 'global_container',
        color: '#585858',
        submitBtn: 'submitBtn',
        card: 'card_'
    })
    const [card, setCard] = useState();
    const [cvv, setCvv] = useState();
    const [expireDate, setExpireDate] = useState();
    const changeTxtCard = (e) => {
        setCard(e.target.value)
    }
    const changeTxtCvv = (e) => {
        setCvv(e.target.value)
    }
    const changeTxtExpire = (e) => {
        setExpireDate(e.target.value)
        console.log(e.target.value)
    }

    const api_data = {
        'fullname': localStorage.getItem('fullname'),
        'license_plate': localStorage.getItem('license'),
        'set_time': localStorage.getItem('startTime'),
        'exp_time': localStorage.getItem('expTime'),
        'location_id': localStorage.getItem('lot'),
        'phone': localStorage.getItem('phone'),
        'token': localStorage.getItem('token'),
        'amount': localStorage.getItem('totalServer'),
        'expireTime': localStorage.getItem('expTimeInSecs'),
        'realTimeServer': localStorage.getItem('realTime'),
        'startTime': localStorage.getItem('startTime'),
        'count': localStorage.getItem('count'),
        'paid': localStorage.getItem('total')
    }

    function pay() {

        var token = tokenPay
        var gettoken = "y"
        var addtoken = "y"
        var firstname = api_data.fullname
        var lastname = " "
        var merchanttxnid = "MerchantTXNIDHere"
        var paymentData = {
            ssl_txn_auth_token: token,
            ssl_card_number: card,
            ssl_exp_date: expireDate,
            ssl_get_token: gettoken,
            ssl_add_token: addtoken,
            ssl_first_name: firstname,
            ssl_last_name: lastname,
            ssl_cvv2cvc2: cvv,
            ssl_merchant_txn_id: merchanttxnid
        };
        var callback = {
            onError: function (error) {
                alert("error", error);
                Sentry.captureMessage("payment_Error");

            },
            onDeclined: function (response) {
                console.log("Result Message=" + response['ssl_result_message']);
                // alert(JSON.stringify(response.ssl_result_message))
                alert(JSON.stringify(response.errorName))
                // alert("declined", response['errorName']);
                Sentry.captureMessage("payment_Declined");

            },
            onApproval: function (response) {
                console.log("Approval Code=" + response['ssl_approval_code']);
                // alert("approval", JSON.stringify(response));
                //         history.push('/success/')
                Sentry.captureMessage("payment_Approval");

                sendDataSuccess(response);
                // history.push('/remaining-time-client/')


            }
        };
        window.ConvergeEmbeddedPayment.pay(paymentData, callback);
        return false;


 // const testDtat ={"ssl_merchant_initiated_unscheduled":"N","ssl_issuer_response":"00","ssl_partner_app_id":"01","ssl_card_number":"51**********3262","ssl_oar_data":"010005071309112012440911000892500000000000073511MCBWZ1C5H","ssl_transaction_type":"SALE","ssl_result":"0","ssl_txn_id":"110922A43-7CCABE6D-066F-4E2F-A201-5D0B775F8813","ssl_avs_response":" ","ssl_approval_code":"073511","ssl_amount":"1.00","ssl_txn_time":"09/11/2022 04:12:44 PM","ssl_account_balance":"0.00","ssl_ps2000_data":"MMCBWZ1C5H      0911","ssl_exp_date":"0626","ssl_result_message":"APPROVAL","ssl_card_short_description":"MC","ssl_get_token":"Y","ssl_par_value":"5001851YHHEEI84M0TVWFSFKM9RAR","ssl_token_response":"SUCCESS","ssl_card_type":"CREDITCARD","ssl_cvv2_response":"M","ssl_token":"5401897451603262","ssl_add_token_response":"Card Added"};
 //        sendDataSuccess(testDtat);


        // history.push('/remaining-time/')
        // sendDataSuccess("ss")
    }



    const sendDataSuccess = (response)=>{


        try {



            if (localStorage.getItem("typePay") == "extend"){

                var startTime = localStorage.getItem("endDateTimeStamp")

            }else{
                var startTime = Date.now()+10000
            }

        }catch (e){
            var startTime = Date.now()+10000

        }


            fetch(`${process.env.REACT_APP_URL_ONESHOT}/v1/Park/ApplyReserve`, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
                    body: JSON.stringify({
                        "parkingId": 4,
                        "fullName": api_data.fullname,
                        "phoneNumber": api_data.phone,
                        "plateNo": api_data.license_plate,
                        "startDateTimeStamp": startTime,
                        "duration": api_data.realTimeServer,
                        "price": api_data.amount,
                        "LotNumber":api_data.location_id,
                        "metadata":JSON.stringify({"sec":api_data.expireTime,"start":api_data.startTime,"end":api_data.exp_time,"count":api_data.count,"lot":api_data.location_id}),
                        "paymentData": {
                            "isSuccess": true,
                              "issuerResponse": response.ssl_issuer_response,
                            "cardNumber": response.ssl_card_number,
                            "token": api_data.token,
                            "jsonData": JSON.stringify(response)
                        }
                    }),
        }).then((res) => res.json())
            .catch((error) => {
            alert('Error1:')})
            .then((data) => {

                if (data.data){

                    callBack(data.data.reqId)

                }else {
                    alert(data.error.message)
                }
                // alert(data.data.token)
                // if(data.status === 400){
                //     setNotifications(`Error >>> ${data.message}`)
                // }
                // else{
                //     setNotifications(null)
                //     setClientSecret(data.clientSecret)
                // }
            })


    }


    const callBack = (id) =>{
        fetch(`${process.env.REACT_APP_URL_ONESHOT}/v1/Park/GetDetail?reqId=${id}`, {
            method: 'GET',
            headers: {"Content-Type": "application/json"},

        }).then((res) => res.json())
            .then((data) => {
                var x1 = JSON.parse(data.data.metaData)
                localStorage.setItem("expTime",x1.end)
                // setTimeout(()=>{
                if( data.data.diffMinutes < 0){
                    localStorage.setItem("count",0)
                    localStorage.setItem("expTimeInSecs",0)
                }else
                {
                    localStorage.setItem("count",x1.count)
                    localStorage.setItem("expTimeInSecs",x1.sec)
                }

                // },1000)
                localStorage.setItem("startTime",data.data.startDate)
                localStorage.setItem("endTime",data.data.endDate)
                localStorage.setItem("phone",data.data.phoneNumber)
                localStorage.setItem("license",data.data.plateNo)
                localStorage.setItem("fullname",data.data.fullName)
                // localStorage.setItem("expTime",data.data.endDate)
                localStorage.setItem("total",data.data.price)
                localStorage.setItem("realTime",data.data.diffMinutes)
                localStorage.setItem("lot",x1.lot)
                localStorage.setItem("endDateTimeStamp",data.data.endDateTimeStamp)

            })

        setTimeout(()=>{
            history.push(`/remaining-time/?id=${id}`)

        },1500)
    }

    useEffect(() => {

        if (darkMode >= 1800 || darkMode <= 600) {
            setDarkModeStyle({
                globalContainer: 'global_container_dark',
                color: 'white',
                submitBtn: 'submitBtn_dark',
                card: 'card_dark'
            })
        }
        fetch(`${process.env.REACT_APP_URL_ONESHOT}/v1/Park/TestPay`, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            // body: JSON.stringify({
            //     amount: calc_stripe_amount(api_data.paid),
            //     description: `Purchased by ${api_data.fullname} owner of ${api_data.license_plate}. Can be contacted at 6470001111`,
            // }),
            body: JSON.stringify({
                firstName: api_data.fullname,
                lastName: " ",
                amount: localStorage.getItem("totalServer")
            }),
        }).then((res) => res.json())
        .then((data) => {
            Sentry.captureMessage("token_success");

            setTokenPay(data.data.token)

            // alert(data.data.token)
            if(data.status === 400){
                setNotifications(`Error >>> ${data.message}`)
                Sentry.captureMessage("token_error");

            }
            else{
                setNotifications(null)
                setClientSecret(data.clientSecret)
            }
        })


    }, [darkMode])

    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                color: 'black' 
            }
        }
    }


    const calc_stripe_amount = (amount) => {
        const split_amount = amount.toString().split('.')
        const output  = parseInt(`${split_amount[0]}${split_amount[1] < 10?`${split_amount[1]}0`:split_amount[1] }`)
        return output
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        // ConvergeEmbeddedPayment.pay();

        //
        if (card == undefined || card == null){
            alert("Credit Card Number Invalid")
        }else if (cvv == undefined || cvv == null){
            alert("cvv Number Invalid")

        }else if(expireDate == undefined || expireDate == null){
            alert("expireDate Invalid")

        }else {
             pay();

        }



        // if(!stripe || !elements) {
            //Stripe JS has not been loaded. Disable form submisison
            // return;
        // }
        // We need to fetch the client secrete from the Server
        // in order to confirmCardPayments





        // setPaymentLoading(true)
        // const paymentResult = await stripe.confirmCardPayment(clientSecret, {
        //     payment_method: {
        //         card: elements.getElement(CardNumberElement, CardExpiryElement, CardCvcElement),
        //         billing_details: {
        //             name: "Decory Herbert"
        //         }
        //     }
        // })
        // setPaymentLoading(false)
        // if(paymentResult.error){
        //     setNotifications(paymentResult.error.message)
        // }else{
        //     if(paymentResult.paymentIntent.status === "succeeded")
        //     setSuccess(true)
        //     setTimeout(()=> {
        //         history.push('/remaining-time/')
        //     }, 2000)
        // }



    } // end of handle submit

    // if(isPaymentLoading){
    //     button_message = 'Loading...'
    // }
    // if(success){
    //     button_message = 'Approved!'
    // }
    // else{
    //     button_message =  'Pay Nows'
    // }
    // const msg_data = {
    //     'number': localStorage.getItem('phone'),
    // }

    // const send_message = async () => {
    //     if(success){
    //         await fetch('https://osparking.pythonanywhere.com/on-payment', {
    //             method: 'POST',
    //             mode: 'cors',
    //             headers: {
    //                 'Access-Control-Allow-Origin':'*',
    //                 'Content-Type': 'application/json'
    //             },
    //             body:JSON.stringify(msg_data)
    //         })
    //         .then((promise) => promise.json())
    //         .then((data) => {
    //             console.log(data)
    //         })
    //     }
    //
    // }
    // send_message()

    const calcTimeout = (val) => {
        setTimeout(() => {
            setNotifications(val)
        }, 1600)
    }


    return (
        <>
		<Header home={false}/>

            <div className={`${darkModeStyle.globalContainer} choose_lot`}>


                    <div>
                        <h1 style={{marginLeft:"20%",position:"absolute", marginTop: '-65%', color: darkModeStyle.color}} className='global_content_title slim'>"2215 Gottingen St"</h1>

                        <h1 className='global_content_title slim' style={{ textAlign: 'center', marginTop: '-50%', color: darkModeStyle.color }}>{status.status}</h1>
                        <form onSubmit={handleSubmit} className={darkModeStyle.card}> {/* this should be a form */}
                            {/* Enter your card numner */}
                            <div className='stacked_'>
                                {/* darkModeStyle.color */}
                                <label style={{ color: 'black' }} id='CCnumberLabel'>Enter Your Card Number</label>
                                <div id='CCnumber'>

                                <input type="text"  style={{marginRight:"25px",width:"250px"}}  onChange={(e)=>{changeTxtCard(e)}} />
                                </div>
                            </div>
                            {/* Expiration CVC & Icon */}
                            <div className='auth_holder'>
                                <div className='stacked_'>

                                    <label style={{ color: darkModeStyle.color }}>Expiration(MMYY)</label>
                                    <InputMask mask="9999" style={{width:"65px"}} onChange={(e)=>{changeTxtExpire(e)}}/>
                                        {/*<input style={{width:"65px"}} onChange={(e)=>{changeTxtExpire(e)}} />*/}

                                    </div>

                                <div className='stacked_'>
                                    <label style={{ color: darkModeStyle.color }}>CVC</label>
                                        <input style={{width:"45px"}} onChange={(e)=>{changeTxtCvv(e)}} />
                                </div>
                                <div>
                                    <img className='ccIcon' src={CreditCardIcon} alt='creditCard_logo'></img>
                                </div>
                            </div>
                            <div className='securePaymentHolder'>
                                <div className='securePayment_'>  {/* display flex */}
                                    {/* image */}
                                    <img className='lock' src={Lock} alt='lock'></img>
                                    <p style={{ color: darkModeStyle.color }}>Secure Payment</p>
                                    {/* text info */}
                                </div>
                            </div>
                            {
                                notifications == null?
                                ''
                                :
                                <Notifier notification={notifications} func={calcTimeout}/>

                            }


                            {/* Button here */}
                            <div className='ccCardSubmissionHolder'>
                                <input style={success ? { backgroundColor: 'green', color: 'white', fontWeight:'bold' ,border: '1px solid green !important' } : { color: '#FF5759', border: '1px solid #FF5759 !important' }} className={`${darkModeStyle.submitBtn} ccCardSubmission`} type='submit' value={isPaymentLoading == true ? 'Processing Payment...': success ? 'Approved!': 'Pay Now'}></input>
                            </div>


                        </form>
                    </div>

                    {/*<Loading />*/}

            </div>
        </>
    )
}
export default StripePayment;

        // const { error, paymentMethod } = await stripe.createPaymentMethod({
        //     type: 'card',
        //     card: element.getElement(CardNumberElement, CardExpiryElement, CardCvcElement),
        // })

        // if (!error) {
        //     try {
        //         setTimeout(() => {
        //
        //         }, 2000)
        //         setSuccess(true)
        //         // const { id } = paymentMethod

        //         fetch('https://osparking.pythonanywhere.com/visitor', {
        //             method: 'POST',
        //             mode: 'cors',
        //             headers: {
        //                 'Access-Control-Allow-Origin':'*',
        //                 'Content-Type': 'application/json'
        //             },
        //             body: JSON.stringify(api_data)
        //         }).then(response => response.json())
        //             .then(data => {
        //                 console.log('Success:', data);
        //             })
        //             .catch((error) => {
        //                 console.error('Error:', error);
        //             });


        //     } catch (error) {
        //         // Change the title information
        //         setStatus({
        //             status: error
        //         })
        //     }
        // }




        // const { error, paymentMethod } = await stripe.createPaymentMethod({
        //     type: 'card',
        //     card: elements.getElement(CardNumberElement, CardExpiryElement, CardCvcElement),
        // })
